@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: #27272a;
}

* {
  scroll-behavior: smooth;
}

.layout {
  @apply mx-auto my-0 flex h-full max-w-screen-lg flex-wrap justify-evenly gap-9 2xl:grid 2xl:grid-cols-4 2xl:place-items-stretch;
}
